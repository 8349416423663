<template>
    <div>
        <input type="text" class="position-fixed top-0 left-0"  
               style="display: none; color: #fff; z-index: 99999; opacity: 0;" id="userCopy"/>
        <div class="card d-flex position-relative" style="height: calc(100dvh - 11rem); border: none; outline: none; box-shadow: none;">
            <div class="position-absolute d-flex align-content-end justify-content-end align-items-center"
                     style="right: 3rem; top: -3.2rem; z-index: 100;">  
                <div class="input-group input-group-sm mr-2" style="width: 13rem;">
                    <input type="text" name="table_search" class="form-control float-right" placeholder="Search"
                            v-model="elements.querySearch" 
                            @input="onSearchInput(true)" 
                            v-on:keyup.enter="onSearchInput(false)">
                    <div class="input-group-append">
                        <button type="submit" class="btn btn-default"
                                @click="onSearchInput(false)">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
                <div>
                    <button type="button" class="btn bg-gradient-primary btn-sm mr-2  text-nowrap" @click="refreshClick">
                        <i :class="`fas ${elements.querySearch !=='' ? 'fa-times' : 'fa-sync-alt'}`"></i>
                            <span class="d-none d-md-inline-block"> &nbsp;{{elements.querySearch !=='' ? 'Clear' : 'Refresh'}}</span> 
                    </button>
                </div>
                <div class="mr-2 mt-0 d-none d-md-block" v-if="elements.data.length > 0">
                    <select class="form-control form-control-sm" style="width: 8rem;"
                            v-model="elements.pagingItem.pageSize" @change="pageClickHandle(1)">
                        <option value="20">20 rows/page</option>
                        <option value="50">50 rows/page</option>
                        <option value="100">100 rows/page</option>
                    </select>
                </div>
            </div>
            <!-- /.card-header -->
            <div class="flex-fill d-flex flex-column card-body p-1 pt-0" v-loading="isLoading">                
                <VuePerfectScrollbar class="table-responsive p-0 flex-fill">
                    <templateList @actions="listActions" :elements="elements.data"/>
                </VuePerfectScrollbar>
            </div>
            <div class="mt-2 mr-1 d-flex align-content-center justify-content-center align-items-center" v-if="elements.data.length > 0">
                <div class="flex-fill pl-3">
                    <h4>{{elements.pagingItem.outRowsNumber}} {{elements.pagingItem.outRowsNumber > 1 ? "items" : "item"}}</h4>
                </div>
                <div class="mr-2 d-flex align-content-center justify-content-center align-items-center">
                    <paginate v-model="elements.pagingItem.pageIndex"
                              :page-count="elements.pagingItem.numberOfPage"
                              :page-range="5"
                              :click-handler="pageClickHandle"
                              :prev-text="'Prev'"
                              :next-text="'Next'"
                              container-class="pagination pagination-sm"
                              page-class="paginate_button page-item"
                              prev-class="paginate_button page-item"
                              next-class="paginate_button page-item"
                              page-link-class="page-link"
                              prev-link-class="page-link"
                              next-link-class="page-link">
                    </paginate>
                </div>
            </div>
            <!-- /.card-body -->
        </div>
        <!-- /.card -->
        <!-- Modal -->
        <div class="modal fade" id="detailUserNote" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            <strong class="text-muted">
                                <i class="fas fa-info-circle text-primary"></i>&nbsp;
                                Detail note for user <span class="text-primary" v-if="detailUserNote !== null">{{detailUserNote.fullName}}</span>
                            </strong>
                        </h5>
                        <button type="button" class="close" @click="closeDetailNote">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body p-1">
                        <comments :detailItem="detailUserNote" module="user" @newestCommentUpdated="newestCommentUpdated"/>
                        <!--<detailUserNote v-if="detailUserNote !== null" :detailUserItem="detailUserNote" 
                                        @newestCommentUpdated="newestCommentUpdated"/>-->
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"  @click="closeDetailNote">Close</button>
                    </div>
                </div>
            </div>
        </div>


        <!-- Modal -->
        <div class="modal fade" id="detailUserInfo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
             style="overflow: hidden;">
            <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 95vw; ">
                <div class="modal-content"  style="width: 95vw !important; height: 90vh;">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            <strong class="text-muted">
                                <i class="fas fa-info-circle text-primary"></i>&nbsp;
                                Detail info for user <span class="text-primary" v-if="elementDetail !== null">{{elementDetail.fullName}}</span>
                            </strong>
                        </h5>
                        <button type="button" class="close" @click="closeDetailInfo" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body d-flex">
                        <detailUser v-if="elementDetail !== null" :detailUserItem="elementDetail"/>
                    </div>
                </div>
            </div>
        </div>


        <!-- Modal -->
        <div class="modal fade" id="detailSameIpUser" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
             style="overflow: hidden;">
            <div class="modal-dialog modal-xl modal-dialog-centered" role="document" >
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            <strong class="text-muted">
                                <i class="fas fa-info-circle text-primary"></i>&nbsp;
                                List user same IP address with <span class="text-primary" v-if="elementDetailSameIp !== null">{{elementDetailSameIp.fullName}}</span>
                            </strong>
                        </h5>
                        <button type="button" class="close" @click="closeDetailSameIpUser" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body d-flex flex-column"  style="height: 85vh; ">
                        <sameIPUserList v-if="elementDetailSameIp !== null" :detailUserItem="elementDetailSameIp"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import commonAct from '@/commonActionHandle.js';
    import usersList from "@/scripts/users/usersList";
    import usersApi from '@/api/common/users';
    export default {
        extends: usersList,
        props: {
            staffId: {
                type: String,
                default: null,
            },
        },
        watch: {
            staffId(newVal) {
                this.getElementsList();
            },
        },
        methods: {
            getElementsList(pageNumber) {
                this.showLoading();
                this.$store.dispatch('CANCEL_PENDING_REQUESTS');
                this.elements.data = [];
                usersApi.getUsersByStaff(this.staffId, pageNumber, this.requestParam).then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        this.elements.data = response.data.data.data;
                        this.elements.pagingItem = response.data.data.pagingItem;
                        this.hideLoading();
                    }
                    else {
                        this.elements.data = [];
                        this.elements.pagingItem = this.defaultPagingItem;
                        this.hideLoading();
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            commonAct.showError(response.data.message || this.$lang.common.error);
                        }
                    }
                }).catch(error => {
                    console.error(error);
                    this.elements.data = [];
                    this.elements.pagingItem = this.defaultPagingItem;
                    this.hideLoading();
                    commonAct.showError(error);

                });
            },
        }
    }
</script>
